<template>

    <v-app id="bg_three">
        <div id="layout_top_three">
            
        </div>
        <div class="three_content d-flex flex-column justify-space-between align-center pa-10">
            <v-row align="stretch">
                <v-col cols="12" class="d-flex flex-column align-center pt-10">
                    <h3 style="color:white;" class="text-center mt-10 t_soal">{{ questions.text }}</h3>

                    <b-button v-for="(response, index) in questions.responses" 
                        @click="saveAnswer(index)"
                        :key="index"
                        pill
                        block
                        size="lg"
                        class="mt-5 shadow"
                        style="background-color:white;height:45px; font-size:10px;border-color:white;color:#26CFBA;">
                        <span class="text_btn">
                            {{ response.text}}
                        </span>
                    </b-button>
                </v-col>
            </v-row>
        </div>
    </v-app>
</template>

<style>
    @font-face {
        font-family: Poppins;
        src: url('../../assets/fonts/Poppins-Medium.ttf');
    }
    #bg_three {
        width: 100%;
        height: 100%;
        background: #26CFBA;
    }
    .three_content{
        z-index:6;
        margin-top:90px;
    }
    .t_soal{
        font-weight: 700;
        font-style: normal;
        font-size: 18px;
    }
    .t_result{
        font-weight: 400;
        font-style: italic;
        font-size:9px;
    }
    .text_btn{
        font-weight: 600;
        font-style: normal;
        font-size:12px;
    }
    #layout_top_three {
        position: fixed;
        width: 100%;
        height: 100%;
        background: url('../../assets/images/bg.png') no-repeat;
        background-size: 100%;  
    }
    @media (min-width : 1000px) {
        #layout_top_three{
            width : 100%;
            height : 100%;
            position :fixed;
        }
    }
</style>

<script>
export default {
  name: 'QuestionThree',
  data: () => ({
     questions: {
        text: "Dalam melakukan sesuatu, saya lebih suka...",
        responses: [
            { text: "Merencanakannya terlebih dahulu" },
            { text: "Go with the flow" },
            { text: "Melakukannya secara spontan" }
        ]
     }
  }),
  methods: {
    saveAnswer (index) {
        localStorage.setItem('3', index)
        this.$router.push({ name: 'QuestionFour'})
    },
    changeColor(){
      var metaThemeColor = document.querySelector("meta[name=theme-color]");
          metaThemeColor.setAttribute("content", "#26CFBA");
    }
  },
  created() {
      this.changeColor();
  },
}
</script>
